@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);
@import "./normalize.css";

* {
  font-family: "나눔고딕", "Apple SD 산돌고딕 Neo", "Nanum Gothic", "맑은고딕", monospace !important;
}

html,
body,
#root {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
}

.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

form {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.result {
  border-radius: 4px;
  padding: 2em;
  width: calc(80% - 4em);
  margin-top: 2em;
  line-height: 27px;
}

.pending {
  border: 1px solid #ffeb3b;
  background-color: #fffde7;
}

.valid {
  background-color: #e1f5fe;
  border: 1px solid #03a9f4;
}

.error {
  background-color: #ffebee;
  color: #f44336;
}
